import { useEffect, useState, useMemo, useRef, useContext } from "react";
import { createMap } from "../../../config/map";
import LayerToggle from "components/common/Map/LayerToggle";
import Search from "./Search";
import { isEqual, uniqueId } from "lodash";
import { MapContext } from "context/MapProvider";
import { usePrevious } from "hooks/usePrevious";
import {
  addDrawInteractionsToMap,
  disableAllInteractions,
  modifySource,
} from "config/interactions";
import DrawTools from "./DrawTools";
import { addLayerToMap } from "util/ol";
import DrawToolsToasts from "./DrawToolsToasts";
import { FipLayer } from "interfaces/FipLayer";
import { FipLayerFolder, isFipLayer } from "interfaces/IIndberetning";
import { useRouter } from "hooks/useRouter";
import { bboxLayer } from "config/layers";
import { IndberetningFilterContext } from "context/IndberetningFilterProvider";
import { getFipFeatureInfo } from "api/fip";
import { MapBrowserEvent } from "ol";
import { useHistory } from "react-router";
import { routes } from "routes/routesConfig";
import { getStednavnFeatureInfo } from "api/stednavn";

export default function Map() {
  const { dispatchToMap, mapState } = useContext(MapContext);
  const { currentUser, filter, search } = useContext(IndberetningFilterContext);
  const { isStednavn } = useRouter();
  const history = useHistory();
  const {
    baselayers,
    layers,
    map,
    activeDrawTool,
    editGeometryState,
    viewState,
  } = mapState;

  const prevLayers = usePrevious(layers);
  const prevBaselayers = usePrevious(baselayers);
  const showDrawTools = activeDrawTool !== undefined;

  const mapId = useMemo(() => uniqueId("map"), []);
  const renderMapDiv = useMemo(() => {
    return <div id={mapId} className="h-100"></div>;
  }, [mapId]);
  const hoveredFeaturesRef = useRef<string[]>([]);

  const onMapClick = useRef<(e: MapBrowserEvent<any>) => void | undefined>();
  useEffect(() => {
    onMapClick.current = (e) => {
      const { pathname } = history.location;
      if (
        !activeDrawTool &&
        !editGeometryState.active &&
        [
          routes.stednavnIndberetCreate.path,
          routes.stednavnIndberetDelete.path,
          routes.stednavnIndberetUpdate.path,
          routes.kortIndberet.path,
        ].indexOf(pathname) < 0
      ) {
        const getIndberetninger = async () => {
          const { pixel } = e;
          const { personId, rolleId } = currentUser;
          const size = e.map.getSize();
          const extent = e.map.getView().calculateExtent(size);
          if (isStednavn) {
            const indberetninger = await getStednavnFeatureInfo({
              extent,
              width: size[0],
              height: size[1],
              personId,
              rolleId,
              filterId: filter.value,
              x: Math.trunc(pixel[0]),
              y: Math.trunc(pixel[1]),
              searchBeskrivelse: search.beskrivelse,
              searchEmne: search.emne,
            });
            if (
              indberetninger?.features &&
              indberetninger.features.length > 0
            ) {
              const first = indberetninger.features[0];
              const sagid = (first.properties?.SagId as number) ?? -1;
              if (sagid > -1) {
                const route = `/stednavn/indberetninger/${sagid}`;
                if (pathname !== route) history.push(route);
              }
            }
          } else {
            const indberetninger = await getFipFeatureInfo({
              extent,
              width: size[0],
              height: size[1],
              personId,
              rolleId,
              filterId: filter.value,
              x: Math.trunc(pixel[0]),
              y: Math.trunc(pixel[1]),
              searchBeskrivelse: search.beskrivelse,
              searchEmne: search.emne,
            });
            if (
              indberetninger?.features &&
              indberetninger.features.length > 0
            ) {
              const first = indberetninger.features[0];
              const sagid = (first.properties?.SagId as number) ?? -1;
              if (sagid > -1) {
                const route = `/kort/indberetninger/${sagid}`;
                if (pathname !== route) history.push(route);
              }
            }
          }
        };
        getIndberetninger();
      }
    };
  }, [
    currentUser,
    filter,
    search,
    isStednavn,
    history,
    activeDrawTool,
    editGeometryState,
  ]);
  useEffect(() => {
    const newmap = createMap(mapId);
    newmap.on("pointermove", (e) => {
      const hoveredFeatures = newmap.getFeaturesAtPixel(e.pixel).map((f) => {
        return f.get("id");
      });
      if (!isEqual(hoveredFeatures, hoveredFeaturesRef.current)) {
        hoveredFeaturesRef.current = hoveredFeatures;
        dispatchToMap({ type: "SET_HOVERED_FEATURES", hoveredFeatures });
      }
    });
    newmap.on("moveend", (e) => {
      const { map } = e;
      const view = map.getView();
      const extent = view.calculateExtent();
      const resolution = view.getResolution();
      const center = view.getCenter();
      const zoom = view.getZoom();
      dispatchToMap({
        type: "SET_VIEWSTATE",
        viewState: { center, resolution, zoom, extent },
      });
    });
    newmap.on("click", (e) => {
      if (onMapClick.current) onMapClick.current(e);
    });
    addDrawInteractionsToMap(newmap);
    addLayerToMap(newmap, bboxLayer);
    disableAllInteractions();
    dispatchToMap({ type: "SET_MAP", map: newmap });
  }, [mapId, dispatchToMap, onMapClick]);

  useEffect(() => {
    const layersChanged = (
      prevLayers: (FipLayer | FipLayerFolder)[] | undefined,
      currLayers: (FipLayer | FipLayerFolder)[]
    ) => {
      if (!prevLayers) return true;
      if (prevLayers.length !== layers.length) return true;
      const prevlayersTitles = prevLayers.map((l) => l.title).join(",");
      const currlayersTitles = currLayers.map((l) => l.title).join(",");
      return prevlayersTitles !== currlayersTitles;
    };
    if (
      map &&
      (layersChanged(prevLayers, layers) ||
        layersChanged(prevBaselayers, baselayers))
    ) {
      prevBaselayers?.forEach((l) => map.removeLayer(l.maplayer));
      prevLayers?.forEach((l) => {
        if (isFipLayer(l)) {
          map.removeLayer(l.maplayer);
        } else {
          l.layers.forEach((folderLayers) => {
            map.removeLayer(folderLayers.maplayer);
          });
        }
      });
      baselayers.forEach((l) => {
        addLayerToMap(map, l.maplayer);
        l.maplayer.setVisible(l.visible);
      });
      layers.forEach((l) => {
        if (isFipLayer(l)) {
          addLayerToMap(map, l.maplayer);
          l.maplayer.setVisible(l.visible);
        } else {
          l.layers.forEach((folderLayers) => {
            addLayerToMap(map, folderLayers.maplayer);
            folderLayers.maplayer.setVisible(folderLayers.visible);
          });
        }
      });
    }
  }, [baselayers, prevBaselayers, layers, prevLayers, map]);
  const containerClasses = mapState.activeDrawTool
    ? "l-map has-focus"
    : "l-map";
  const [showToast, setShowToast] = useState(true);

  const setVisibleBaselayer = (layer: FipLayer) => {
    dispatchToMap({ type: "SET_VISIBLE_BASELAYER", layer });
  };
  const setLayerVisibility = (layer: FipLayer, visible: boolean) => {
    dispatchToMap({ type: "SET_LAYER_VISIBILITY", layer, visible });
  };
  return (
    <div className={containerClasses}>
      {renderMapDiv}
      <div className="l-map__ui">
        {map && (
          <Search
            placeholder={
              isStednavn ? "Søg stednavn" : "Søg adresse, stednavn, mm."
            }
            map={map}
            resources={isStednavn ? ["stednavn", "adresse", "postnummer"] : ["adresse", "stednavn", "postnummer", "sogn"]}
          />
        )}
        <DrawToolsToasts
          activeDrawTool={activeDrawTool}
          setShowToast={setShowToast}
          showToast={showToast}
        />

        <LayerToggle
          baselayers={baselayers}
          layers={layers}
          setLayerVisibility={setLayerVisibility}
          setVisibleBaselayer={setVisibleBaselayer}
          viewState={viewState}
        />

        {editGeometryState.active && (
          <div className="c-edit-draw gap-2 d-flex shadow">
            <button
              className="btn btn-outline-primary"
              onClick={() => {
                dispatchToMap({ type: "CANCEL_EDIT_GEOMETRY" });
              }}
            >
              Fortryd
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                const modifyFeatures = modifySource.getFeatures();
                const modifyFeature =
                  modifyFeatures.length > 0 ? modifyFeatures[0] : undefined;
                if (modifyFeature) {
                  dispatchToMap({
                    type: "SAVE_EDIT_GEOMETRY",
                    clonedGeometry: modifyFeature.clone().getGeometry(),
                  });
                }
              }}
            >
              Gem ændring
            </button>
          </div>
        )}

        {showDrawTools && (
          <DrawTools
            showToast={() => setShowToast(true)}
            allowedDrawTools={
              isStednavn
                ? ["line", "point", "polygon"]
                : ["line", "point", "polygon", "udpeg"]
            }
          />
        )}

        <div className="c-attribution">
          <span className="visually-hidden">Kilder til kort: </span>
          <a href="https://septima.dk/services" target="blank" rel="noopener">
            © Septima
          </a>
          ,{" "}
          <a href="https://kortforsyningen.dk/" target="blank" rel="noopener">
            © SDFE
          </a>
        </div>
      </div>
    </div>
  );
}
