import { postIndberetning, sagsbehandlingApi } from "api/stednavn";
import ValidationErrorMessage from "components/common/FormElements/ValidationErrorMessage";
import AccepterVilkår from "components/common/Indberet/AccepterVilkår";
import EmailInput from "components/common/Indberet/EmailInput";
import { ModalContext } from "components/common/Modal/Modal";
import {
  finishedDrawingSource,
  setDrawingCallback,
  tempDrawingSource,
} from "config/interactions";
import { Kategori } from "config/StednavnConfig";
import { AuthContext } from "context/AuthProvider";
import { DrawTool, MapContext } from "context/MapProvider";
import { ToastContext } from "context/ToastProvider";
import { Validation } from "interfaces/IIndberetning";
import { Feature } from "ol";
import { GeoJSON } from "ol/format";
import Geometry from "ol/geom/Geometry";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { useHistory } from "react-router";
import { NewFileState } from "routes/Fip/FipIndberet";
import { routes } from "routes/routesConfig";
import { zoomToFeature } from "util/ol";
import Drawing from "../components/Drawing";
import File from "../components/File";
import FindStednavn from "../components/FindStednavn";
import KategoriSelect from "../components/KategoriSelect";
import Kommentar from "../components/Kommentar";
import StednavnInput from "../components/StednavnInput";
import Tilbage from "../components/Tilbage";
import { NyIndberetningState } from "../StednavnIndberet";
import { mapKategori, prepareUpdateStednavnSag } from "../util";
import { StednavnSearchResult } from "interfaces/IGSearch";

export interface UpdateStednavnState extends NyIndberetningState {
  kategori?: { value: number; label: string };
  searchResult: StednavnSearchResult | undefined;
  validation: {
    searchResult: Validation;
    ændringstype: Validation;
    nytStednavn: Validation;
    vilkårAccepteret: Validation;
    beskrivelse: Validation;
    filEllerGeometri: Validation;
    email: Validation;
    emne: Validation;
    kategori: Validation;
  };
}

export default function UpdateStednavn() {
  const history = useHistory();

  const { authState, setCurrentUserEmail } = useContext(AuthContext);
  const toast = useContext(ToastContext);
  const { showModal, closeModal } = useContext(ModalContext);

  const searchRef = useRef<HTMLDivElement>(null);
  const stednavnInputRef = useRef<HTMLDivElement>(null);
  const tegningInputRef = useRef<HTMLDivElement>(null);
  const kommentarRef = useRef<HTMLDivElement>(null);
  const kategoriRef = useRef<HTMLSelectElement>(null);
  const emailRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    return () => {
      finishedDrawingSource.clear();
      tempDrawingSource.clear();
    };
  }, []);
  const { dispatchToMap, mapState } = useContext(MapContext);
  useEffect(() => {
    return () => {
      dispatchToMap({ type: "SET_DRAWTOOL", drawTool: undefined });
      dispatchToMap({ type: "CANCEL_EDIT_GEOMETRY" });
    };
  }, [dispatchToMap]);
  const { map, activeDrawTool, hoveredFeatures } = mapState;
  const [newFile, setNewFile] = useState<NewFileState>({
    state: "IDLE",
    file: undefined,
  });
  const indberetningReducer = (
    state: UpdateStednavnState,
    action:
      | {
          type: "SET_SEARCHRESULT";
          searchResult: StednavnSearchResult | undefined;
        }
      | { type: "SET_KATEGORI"; value: number }
      | { type: "SET_ALL_TOUCHED" }
      | { type: "SET_NYTSTEDNAVN"; navn: string }
      | { type: "SET_VILKAAR_ACCEPTERET"; vilkårAccepteret: boolean }
      | { type: "SET_BESKRIVELSE"; beskrivelse: string }
      | { type: "SET_EMAIL"; email: string }
      | {
          type: "ADD_FILE";
          fileInfo: {
            filId: number;
            geometri: Feature<Geometry>;
            originalFilNavn: string;
          };
        }
      | { type: "REMOVE_FILE" }
      | {
          type: "ADD_DRAWING";
          drawing: Feature<Geometry>;
        }
      | { type: "REMOVE_DRAWING" }
      | { type: "RESET_NOTIFY_BESKRIVELSE" }
      | { type: "RESET_NOTIFY_EMAIL" }
      | { type: "RESET_NOTIFY_EMNE" }
      | { type: "RESET_NOTIFY_FILELLERGEOMETRI" }
      | { type: "RESET_NOTIFY_KATEGORI" }
      | { type: "RESET_NOTIFY_NYTSTEDNAVN" }
      | { type: "RESET_NOTIFY_VILKAAR_ACCEPTERET" }
      | { type: "RESET_NOTIFY_ÆNDRINGSTYPE" }
      | { type: "RESET_NOTIFY_SEARCHRESULT" }
  ): UpdateStednavnState => {
    dispatchToMap({ type: "SET_DRAWTOOL", drawTool: undefined });
    dispatchToMap({ type: "CANCEL_EDIT_GEOMETRY" });
    switch (action.type) {
      case "SET_SEARCHRESULT": {
        const { searchResult } = action;
        if (searchResult) {
          const feature = new GeoJSON().readFeature(searchResult.geometri);
          feature.set("visningstekst", searchResult.visningstekst);
          feature.set("type", searchResult.stednavn_type);
          finishedDrawingSource.clear();
          finishedDrawingSource.addFeature(feature);
          tempDrawingSource.clear();
          tempDrawingSource.addFeature(feature);
          const type = feature.get("type");
          const i: UpdateStednavnState = {
            ...state,
            searchResult,
            geometri: feature,
            kategori: mapKategori(type),
            nytStednavn: searchResult.visningstekst,
            emne: `Ændret stednavn - ${searchResult.visningstekst}`,
            validation: {
              ...state.validation,
              filEllerGeometri: {
                ...state.validation.filEllerGeometri,
                valid: true,
                touched: true,
              },
              searchResult: {
                ...state.validation.searchResult,
                valid: true,
                touched: true,
              },
              kategori: {
                ...state.validation.kategori,
                valid: true,
                touched: true,
              },
              nytStednavn: {
                ...state.validation.nytStednavn,
                valid: true,
                touched: true,
              },
            },
          };
          return i;
        } else {
          // "remove drawing"
          finishedDrawingSource.clear();
          tempDrawingSource.clear();
          const i: UpdateStednavnState = {
            ...state,
            searchResult,
            geometri: undefined,
            validation: {
              ...state.validation,
              filEllerGeometri: {
                ...state.validation.filEllerGeometri,
                valid: state.fil ? true : false,
                touched: true,
              },
              searchResult: {
                ...state.validation.searchResult,
                valid: false,
                touched: true,
              },
            },
          };
          return i;
        }
      }
      case "SET_ALL_TOUCHED": {
        const validation = { ...state.validation };
        Object.entries(validation).forEach(([key, v]) => {
          v.touched = true;
        });
        return { ...state, validation };
      }
      case "SET_VILKAAR_ACCEPTERET": {
        return {
          ...state,
          vilkårAccepteret: action.vilkårAccepteret,
          validation: {
            ...state.validation,
            vilkårAccepteret: {
              ...state.validation.vilkårAccepteret,
              valid: action.vilkårAccepteret,
              touched: true,
            },
          },
        };
      }
      case "SET_BESKRIVELSE": {
        const i: UpdateStednavnState = {
          ...state,
          beskrivelse: action.beskrivelse,
          validation: {
            ...state.validation,
            beskrivelse: {
              ...state.validation.beskrivelse,
              valid: action.beskrivelse ? true : false,
              touched: true,
            },
          },
        };
        return i;
      }
      case "SET_EMAIL": {
        const i: UpdateStednavnState = {
          ...state,
          email: action.email,
          validation: {
            ...state.validation,
            email: {
              ...state.validation.email,
              valid: action.email ? true : false,
              touched: true,
            },
          },
        };
        return i;
      }
      case "SET_NYTSTEDNAVN": {
        const i: UpdateStednavnState = {
          ...state,
          nytStednavn: action.navn,
          emne: `Ændret stednavn - ${action.navn}`,
          validation: {
            ...state.validation,
            nytStednavn: {
              ...state.validation.nytStednavn,
              valid: action.navn ? true : false,
              touched: true,
            },
          },
        };
        return i;
      }
      case "ADD_FILE": {
        finishedDrawingSource.clear();
        finishedDrawingSource.addFeature(action.fileInfo.geometri);
        if (state.geometri) {
          finishedDrawingSource.addFeature(state.geometri);
        }
        const i: UpdateStednavnState = {
          ...state,
          fil: {
            ...action.fileInfo,
            beskrivelse: "",
            dato: new Date().toISOString(),
          },
        };
        setNewFile({ state: "IDLE", file: undefined });
        return i;
      }
      case "REMOVE_FILE": {
        const i: UpdateStednavnState = {
          ...state,
          fil: undefined,
        };
        finishedDrawingSource.clear();
        if (state.geometri) {
          finishedDrawingSource.addFeature(state.geometri);
        }
        return i;
      }
      case "ADD_DRAWING": {
        finishedDrawingSource.clear();
        finishedDrawingSource.addFeature(action.drawing);
        const i: UpdateStednavnState = {
          ...state,
          geometri: action.drawing,
          validation: {
            ...state.validation,
            filEllerGeometri: {
              ...state.validation.filEllerGeometri,
              valid: true,
              touched: true,
            },
          },
        };
        return i;
      }
      case "REMOVE_DRAWING": {
        finishedDrawingSource.clear();
        const i: UpdateStednavnState = {
          ...state,
          geometri: undefined,
          validation: {
            ...state.validation,
            filEllerGeometri: {
              ...state.validation.filEllerGeometri,
              valid: false,
              touched: true,
            },
          },
        };
        return i;
      }
      case "SET_KATEGORI": {
        const kategori = Kategori.find((k) => k.value === action.value);
        if (!kategori) return state;
        const i: UpdateStednavnState = {
          ...state,
          kategori,
          validation: {
            ...state.validation,
            kategori: {
              ...state.validation.kategori,
              valid: true,
              touched: true,
            },
          },
        };
        return i;
      }
      case "RESET_NOTIFY_BESKRIVELSE": {
        return {
          ...state,
          validation: {
            ...state.validation,
            beskrivelse: { ...state.validation.beskrivelse, notify: false },
          },
        };
      }
      case "RESET_NOTIFY_EMAIL": {
        return {
          ...state,
          validation: {
            ...state.validation,
            email: { ...state.validation.email, notify: false },
          },
        };
      }
      case "RESET_NOTIFY_EMNE": {
        return {
          ...state,
          validation: {
            ...state.validation,
            emne: { ...state.validation.emne, notify: false },
          },
        };
      }
      case "RESET_NOTIFY_FILELLERGEOMETRI": {
        return {
          ...state,
          validation: {
            ...state.validation,
            filEllerGeometri: {
              ...state.validation.filEllerGeometri,
              notify: false,
            },
          },
        };
      }
      case "RESET_NOTIFY_KATEGORI": {
        return {
          ...state,
          validation: {
            ...state.validation,
            kategori: { ...state.validation.kategori, notify: false },
          },
        };
      }
      case "RESET_NOTIFY_NYTSTEDNAVN": {
        return {
          ...state,
          validation: {
            ...state.validation,
            nytStednavn: { ...state.validation.nytStednavn, notify: false },
          },
        };
      }
      case "RESET_NOTIFY_VILKAAR_ACCEPTERET": {
        return {
          ...state,
          validation: {
            ...state.validation,
            vilkårAccepteret: {
              ...state.validation.vilkårAccepteret,
              notify: false,
            },
          },
        };
      }
      case "RESET_NOTIFY_ÆNDRINGSTYPE": {
        return {
          ...state,
          validation: {
            ...state.validation,
            ændringstype: { ...state.validation.ændringstype, notify: false },
          },
        };
      }
      case "RESET_NOTIFY_SEARCHRESULT": {
        return {
          ...state,
          validation: {
            ...state.validation,
            searchResult: { ...state.validation.searchResult, notify: false },
          },
        };
      }
    }
  };

  const initialState: UpdateStednavnState = {
    kategori: undefined,
    searchResult: undefined,
    ændringstype: "opret",
    vilkårAccepteret: false,
    nytStednavn: "",
    beskrivelse: "",
    geometri: undefined,
    fil: undefined,
    email: "",
    emne: "",
    validation: {
      searchResult: {
        valid: false,
        message: "Fremsøg stednavnet, der skal ændres",
        touched: false,
        notify: false,
      },
      ændringstype: {
        valid: false,
        message: "",
        touched: false,
        notify: false,
      },
      nytStednavn: {
        valid: false,
        message: "Stednavn skal udfyldes",
        touched: false,
        notify: false,
      },
      vilkårAccepteret: {
        valid: false,
        message: "Betingelserne skal være accepteret",
        touched: false,
        notify: false,
      },
      beskrivelse: {
        valid: false,
        message: "Beskrivelse skal udfyldes",
        touched: false,
        notify: false,
      },
      emne: {
        valid: false,
        message: "Vælg et eksisterende stednavn ved at søge",
        touched: false,
        notify: false,
      },
      filEllerGeometri: {
        valid: false,
        message: "Indberetningen skal indeholde en opdatering i kortet",
        touched: false,
        notify: false,
      },
      email: {
        valid: false,
        message: "Email skal udfyldes",
        touched: false,
        notify: false,
      },
      kategori: {
        valid: false,
        message: "Vælg kategori",
        touched: false,
        notify: false,
      },
    },
  };
  const [indberetning, setIndberetning] = useReducer(
    indberetningReducer,
    initialState
  );

  // Set email when authstate is ready
  useEffect(() => {
    const userEmail = authState.currentUser.email;
    if (userEmail && userEmail !== indberetning.email) {
      setIndberetning({ type: "SET_EMAIL", email: userEmail });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  useEffect(() => {
    setDrawingCallback((drawing, isValid) => {
      if (!isValid) {
        finishedDrawingSource.removeFeature(drawing);
        toast({
          type: "danger",
          content: {
            header: "Ugyldig geometri",
            message: `Den angivne geometri er ugyldig, hvilket kan skyldes, at geometrien "krydser" sig selv.\n\nTegn venligst en ny geometri.`,
          },
        });
      } else {
        if (newFile.state === "UPLOADED" && newFile.file) {
          setIndberetning({
            type: "ADD_FILE",
            fileInfo: {
              filId: parseInt(newFile.file.id),
              geometri: drawing,
              originalFilNavn: newFile.file.originalFilNavn,
            },
          });
        } else {
          setIndberetning({
            type: "ADD_DRAWING",
            drawing,
          });
        }
        dispatchToMap({
          type: "SET_DRAWTOOL",
          drawTool: undefined,
        });
      }
    });
  }, [
    map,
    setIndberetning,
    indberetning,
    newFile.file,
    newFile.state,
    dispatchToMap,
    toast,
  ]);
  const setSearchResult = (searchResult) =>
    setIndberetning({ type: "SET_SEARCHRESULT", searchResult });

  return (
    <div>
      <div className="mt-5">
        <Tilbage />
        <h1>Ændring af stednavn</h1>
        <p className="lead fw-semibold text-dark">
          Foreslå ændring af et eksisterende stednavn.
        </p>

        <div
          className={
            indberetning.validation.searchResult.touched &&
            !indberetning.validation.searchResult.valid
              ? "l-section-wrapper form-control is-invalid "
              : "l-section-wrapper"
          }
          ref={searchRef}
        >
          <FindStednavn
            map={map}
            searchResult={indberetning.searchResult}
            setSearchResult={setSearchResult}
            ændringstype={"ændre"}
          />
        </div>
        <ValidationErrorMessage
          show={
            indberetning.validation.searchResult.touched &&
            !indberetning.validation.searchResult.valid
          }
          message={indberetning.validation.searchResult.message}
          notify={indberetning.validation.searchResult.notify}
          resetNotify={() =>
            setIndberetning({ type: "RESET_NOTIFY_SEARCHRESULT" })
          }
        />

        <div className="l-section" ref={stednavnInputRef}>
          <h2>Ændre stednavn</h2>
          <p>
            Herunder kan du ændre i navnet på stedet eller stednavnets kategori.
          </p>
          <StednavnInput
            notify={indberetning.validation.nytStednavn.notify}
            resetNotify={() =>
              setIndberetning({ type: "RESET_NOTIFY_NYTSTEDNAVN" })
            }
            ændringstype={"opret"}
            showValidationWarning={
              indberetning.validation.nytStednavn.touched &&
              !indberetning.validation.nytStednavn.valid
            }
            setStednavn={(navn) =>
              setIndberetning({
                type: "SET_NYTSTEDNAVN",
                navn: navn,
              })
            }
            stednavn={indberetning.nytStednavn}
            validationWarningMessage={
              indberetning.validation.nytStednavn.message
            }
          />
          <KategoriSelect
            isInvalid={
              indberetning.validation.kategori.touched &&
              !indberetning.validation.kategori.valid
            }
            ref={kategoriRef}
            kategorier={Kategori}
            currentKategori={indberetning.kategori}
            setCurrentKategori={(id) => {
              const value = parseInt(id);
              setIndberetning({ type: "SET_KATEGORI", value });
            }}
            notify={indberetning.validation.kategori.notify}
            resetNotify={() =>
              setIndberetning({ type: "RESET_NOTIFY_KATEGORI" })
            }
          />
        </div>
        <div
          ref={tegningInputRef}
          className={
            indberetning.validation.filEllerGeometri.touched &&
            !indberetning.validation.filEllerGeometri.valid
              ? "l-section-wrapper form-control is-invalid "
              : "l-section-wrapper"
          }
        >
          <Drawing
            type="update"
            setDrawTool={(drawTool: DrawTool) => {
              dispatchToMap({
                type: "SET_DRAWTOOL",
                drawTool,
              });
            }}
            activeDrawTool={activeDrawTool}
            newFile={newFile}
            onDeleteClick={() => {
              setIndberetning({ type: "REMOVE_DRAWING" });
            }}
            kategori={indberetning.kategori?.label ?? ""}
            geometri={indberetning.geometri}
            hoveredFeatures={mapState.hoveredFeatures}
            setHoveredFeatures={(ids) =>
              dispatchToMap({
                type: "SET_HOVERED_FEATURES",
                hoveredFeatures: ids,
              })
            }
            zoomToDrawing={() => {
              if (map && indberetning.geometri) {
                zoomToFeature(map, indberetning.geometri);
              }
            }}
            onEditGeometryClick={() => {
              if (newFile.file) {
                setNewFile({ file: undefined, state: "IDLE" });
              }
              dispatchToMap({
                type: "SET_DRAWTOOL",
                drawTool: undefined,
              });
              if (indberetning.geometri) {
                dispatchToMap({
                  type: "EDIT_GEOMETRY",
                  geometryToEdit: indberetning.geometri,
                });
              }
            }}
            showModal={showModal}
            closeModal={closeModal}
          />
        </div>
        <ValidationErrorMessage
          show={
            indberetning.validation.filEllerGeometri.touched &&
            !indberetning.validation.filEllerGeometri.valid
          }
          message={indberetning.validation.filEllerGeometri.message}
          notify={indberetning.validation.filEllerGeometri.notify}
          resetNotify={() =>
            setIndberetning({ type: "RESET_NOTIFY_FILELLERGEOMETRI" })
          }
        />
        <div className="l-section">
          <File
            setDrawTool={(drawTool: DrawTool) => {
              dispatchToMap({
                type: "SET_DRAWTOOL",
                drawTool,
              });
            }}
            fil={indberetning.fil}
            newFile={newFile}
            setNewFile={setNewFile}
            activeDrawTool={activeDrawTool}
            onClick={() => {
              if (mapState.map && indberetning.fil) {
                zoomToFeature(mapState.map, indberetning.fil.geometri);
              }
            }}
            hoveredFeatures={hoveredFeatures}
            setHoveredFeatures={(ids) =>
              dispatchToMap({
                type: "SET_HOVERED_FEATURES",
                hoveredFeatures: ids,
              })
            }
            removeFil={() => setIndberetning({ type: "REMOVE_FILE" })}
            onEditGeometryClick={() => {
              dispatchToMap({
                type: "SET_DRAWTOOL",
                drawTool: undefined,
              });
              if (indberetning.fil) {
                dispatchToMap({
                  type: "EDIT_GEOMETRY",
                  geometryToEdit: indberetning.fil.geometri,
                });
              }
            }}
            showModal={showModal}
            closeModal={closeModal}
            sagsbehandlingApi={sagsbehandlingApi}
          />
        </div>

        <div className="l-section" ref={kommentarRef}>
          <h2>Kommentar til ændring af stednavn</h2>
          <p>
            Giv en beskrivelse af ændringen af stednavnet og begrundelsen for
            dette.{" "}
          </p>
          <Kommentar
            notify={indberetning.validation.beskrivelse.notify}
            resetNotify={() =>
              setIndberetning({
                type: "RESET_NOTIFY_BESKRIVELSE",
              })
            }
            kommentar={indberetning.beskrivelse}
            setKommentar={(k) =>
              setIndberetning({ type: "SET_BESKRIVELSE", beskrivelse: k })
            }
            showValidationWarning={
              indberetning.validation.beskrivelse.touched &&
              !indberetning.validation.beskrivelse.valid
            }
            validationMessage={indberetning.validation.beskrivelse.message}
          />
        </div>
        <section className={`l-section`}>
          <h2>Send indberetning</h2>
          <p>
            Vi har brug for din e-mailadresse for at kunne give dig besked om
            behandlingen af din indberetning og for at kunne kontakte dig, hvis
            vi eventuelt har spørgsmål til din indberetning eller har brug for
            flere oplysninger. Hvis du tidligere har indberettet, vil din e-mail
            allerede være udfyldt. Du kan rette din e-mailadresse i det udfyldte
            felt, hvis der er brug for det.
          </p>
          <div className="row g-3 mb-3">
            <EmailInput
              ref={emailRef}
              email={indberetning.email}
              setEmail={(email) => {
                setIndberetning({ type: "SET_EMAIL", email });
              }}
              notifyValidationWarning={indberetning.validation.email.notify}
              resetNotifyValidationWarning={() =>
                setIndberetning({ type: "RESET_NOTIFY_EMAIL" })
              }
              disableForm={false}
              showValidationWarning={
                indberetning.validation.email.touched &&
                !indberetning.validation.email.valid
              }
              validationWarningMessage={indberetning.validation.email.message}
            />
            <AccepterVilkår
              notify={indberetning.validation.vilkårAccepteret.notify}
              resetNotify={() =>
                setIndberetning({
                  type: "RESET_NOTIFY_VILKAAR_ACCEPTERET",
                })
              }
              disabled={false}
              checked={indberetning.vilkårAccepteret}
              setChecked={(checked) => {
                setIndberetning({
                  type: "SET_VILKAAR_ACCEPTERET",
                  vilkårAccepteret: checked,
                });
              }}
              showValidationWarning={
                indberetning.validation.vilkårAccepteret.touched &&
                !indberetning.validation.vilkårAccepteret.valid
              }
              validationWarningMessage={
                indberetning.validation.vilkårAccepteret.message
              }
              showModal={showModal}
              closeModal={closeModal}
            />
            <div className="col-12">
              <button
                onClick={() => {
                  async function post() {
                    try {
                      const i = await postIndberetning(
                        authState.currentUser.personId,
                        indberetning.email,
                        prepareUpdateStednavnSag(indberetning)
                      );
                      if (i.id) {
                        if (authState.currentUser.email !== indberetning.email)
                          setCurrentUserEmail(indberetning.email);
                        toast({
                          content: {
                            message:
                              "Tak for din indberetning, her kan du se en opsummering af din indberetning",
                          },
                        });
                        history.push(
                          routes.stednavnIndberetning.path.replace(
                            ":id",
                            i.id.toString()
                          )
                        );
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }
                  setIndberetning({ type: "SET_ALL_TOUCHED" });
                  if (mapState.activeDrawTool) {
                    toast({
                      type: "danger",
                      content: {
                        header: "Tegner opdatering",
                        message:
                          "Gør færdig opdateringen i kortet inden du sender indberetningen ind.",
                      },
                    });
                  } else if (mapState.editGeometryState.active) {
                    toast({
                      type: "danger",
                      content: {
                        header: "Redigerer opdatering",
                        message:
                          "Gør færdig redigeringen i kortet inden du sender indberetningen ind.",
                      },
                    });
                  } else if (!indberetning.validation.searchResult.valid) {
                    searchRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  } else if (!indberetning.validation.nytStednavn.valid) {
                    stednavnInputRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  } else if (!indberetning.validation.kategori.valid) {
                    kategoriRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  } else if (!indberetning.validation.filEllerGeometri.valid) {
                    tegningInputRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  } else if (!indberetning.validation.beskrivelse.valid) {
                    kommentarRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  } else if (!indberetning.validation.email.valid) {
                    emailRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  } else if (!indberetning.validation.vilkårAccepteret.valid) {
                    emailRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  } else {
                    post();
                  }
                }}
                type="button"
                className="btn btn-lg btn-primary mt-4"
              >
                Indsend indberetning
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
