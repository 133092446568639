import { get } from "../index";
import {
  AddressSearchResult,
  KommuneSearchResult,
  MatrikelSearchResult,
  OpstillingskredsSearchResult,
  PolitikredsSearchResult,
  PostnummerSearchResult,
  RegionSearchResult,
  RetskretsSearchResult,
  SearchResult,
  SognSearchResult,
  StednavnSearchResult,
} from "../../interfaces/IGSearch";

export type ResourceType =
  | "adresse"
  | "kommune"
  | "matrikel"
  | "opstillingskreds"
  | "politikreds"
  | "postnummer"
  | "region"
  | "retskreds"
  | "sogn"
  | "stednavn";

const apiPrefix = "https://api.dataforsyningen.dk/rest/gsearch/v2.0/";

const limit = 10;

export const getSearchResult = async (
  query: string,
  resources?: ResourceType[]
): Promise<SearchResult[]> => {
  try {
    const {
      adressePromise,
      kommunePromise,
      matrikelPromise,
      opstillingskredsPromise,
      politikredsPromise,
      postnummerPromise,
      regionPromise,
      retskredsPromise,
      sognPromise,
      stednavnePromise,
    } = setupPromises(query, resources);
    const adresser = await adressePromise;
    const kommuner = await kommunePromise;
    const opstillingskredser = await opstillingskredsPromise;
    const politikredser = await politikredsPromise;
    const postnumre = await postnummerPromise;
    const regioner = await regionPromise;
    const retskredse = await retskredsPromise;
    const sogne = await sognPromise;
    const matrikler = await matrikelPromise;
    const stednavne = await stednavnePromise;
    const result: SearchResult[] = [
      ...adresser.map((sr) => {
        return { ...sr, type: "adresse" };
      }),
      ...stednavne.map((sr) => {
        return { ...sr, type: "stednavn" };
      }),
      ...kommuner.map((sr) => {
        return { ...sr, type: "kommune" };
      }),
      ...matrikler.map((sr) => {
        return { ...sr, type: "matrikel" };
      }),
      ...opstillingskredser.map((sr) => {
        return { ...sr, type: "opstillingskreds" };
      }),
      ...politikredser.map((sr) => {
        return { ...sr, type: "politikreds" };
      }),
      ...postnumre.map((sr) => {
        return { ...sr, type: "postnummer" };
      }),
      ...regioner.map((sr) => {
        return { ...sr, type: "regioner" };
      }),
      ...retskredse.map((sr) => {
        return { ...sr, type: "retskreds" };
      }),
      ...sogne.map((sr) => {
        return { ...sr, type: "sogn" };
      }),
    ];
    return cutResultToLimit(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

function setupPromises(query: string, resources?: ResourceType[]) {
  const queryString = `token=${process.env.REACT_APP_KFTOKEN}&limit=${limit}&q=${query}`;

  const adressePromise = includeResource("adresse", resources)
    ? get<AddressSearchResult[]>({
        query: `adresse?${queryString}`,
        apiPrefix,
      })
    : Promise.resolve([]);

  const kommunePromise = includeResource("kommune", resources)
    ? get<KommuneSearchResult[]>({
        query: `kommune?${queryString}`,
        apiPrefix,
      })
    : Promise.resolve([]);

  const matrikelPromise = includeResource("matrikel", resources)
    ? get<MatrikelSearchResult[]>({
        query: `matrikel?${queryString}`,
        apiPrefix,
      })
    : Promise.resolve([]);

  const opstillingskredsPromise = includeResource("opstillingskreds", resources)
    ? get<OpstillingskredsSearchResult[]>({
        query: `opstillingskreds?${queryString}`,
        apiPrefix,
      })
    : Promise.resolve([]);

  const politikredsPromise = includeResource("politikreds", resources)
    ? get<PolitikredsSearchResult[]>({
        query: `politikreds?${queryString}`,
        apiPrefix,
      })
    : Promise.resolve([]);

  const postnummerPromise = includeResource("postnummer", resources)
    ? get<PostnummerSearchResult[]>({
        query: `postnummer?${queryString}`,
        apiPrefix,
      })
    : Promise.resolve([]);

  const regionPromise = includeResource("region", resources)
    ? get<RegionSearchResult[]>({
        query: `region?${queryString}`,
        apiPrefix,
      })
    : Promise.resolve([]);

  const retskredsPromise = includeResource("retskreds", resources)
    ? get<RetskretsSearchResult[]>({
        query: `retskreds?${queryString}`,
        apiPrefix,
      })
    : Promise.resolve([]);

  const sognPromise = includeResource("sogn", resources)
    ? get<SognSearchResult[]>({
        query: `sogn?${queryString}`,
        apiPrefix,
      })
    : Promise.resolve([]);

  const stednavnePromise = includeResource("stednavn", resources)
    ? get<StednavnSearchResult[]>({
        query: `stednavn?${queryString}`,
        apiPrefix,
      })
    : Promise.resolve([]);
  return {
    adressePromise,
    kommunePromise,
    matrikelPromise,
    opstillingskredsPromise,
    politikredsPromise,
    postnummerPromise,
    regionPromise,
    retskredsPromise,
    sognPromise,
    stednavnePromise,
  };
}
function includeResource(
  resource: ResourceType,
  resources: ResourceType[] | undefined
) {
  if (!resources || resources.length === 0) return true;
  return resources.indexOf(resource) > -1;
}

/**
 * If we have more resultitems than the limit of each, we want
 * to cut the results down to the limit size in a smart way.
 * @param result
 * @returns
 */
function cutResultToLimit(result: SearchResult[]): SearchResult[] {
  // console.log(result);
  return result;
}
