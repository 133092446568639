import { CSSProperties, useEffect, useRef, useState } from "react";
import Downshift from "downshift";
import { Map } from "ol";
import { SearchResult } from "interfaces/IGSearch";
import { ResourceType, getSearchResult } from "api/datafordeleren/g-search";
import { zoomToFeature } from "util/ol";
import { GeoJSON } from "ol/format";

interface ISearchProps {
  map: Map;
  resources: ResourceType[];
  placeholder?: string;
  onResult?: (searchResult: SearchResult) => any;
  containerClassName?: string;
  containerStyle?: CSSProperties;
  inputClassName?: string;
  inputStyle?: CSSProperties;
  listStyle?: CSSProperties;
  listItemStyle?: CSSProperties;
  extSearchString?: string;
  extSetSearchString?: React.Dispatch<React.SetStateAction<string>>;
  inputId?: string;
}
export default function Search(props: ISearchProps) {
  const {
    map,
    resources,
    placeholder,
    onResult,
    containerClassName,
    containerStyle,
    inputClassName,
    inputStyle,
    listStyle,
    listItemStyle,
    extSearchString,
    extSetSearchString,
    inputId,
  } = props;
  const [intSearchString, intSetSearchString] = useState("");
  const [searchString, setSearchString] =
    extSearchString !== undefined && extSetSearchString
      ? [extSearchString, extSetSearchString]
      : [intSearchString, intSetSearchString];
  const [searchResult, setSearchResult] = useState<SearchResult[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const cache = useRef<any>({});
  useEffect(() => {
    const search = async () => {
      if (searchString && searchString.length > 2) {
        const res = cache.current[searchString]
          ? cache.current[searchString]
          : await getSearchResult(searchString, resources);
        cache.current[searchString] = res;
        if (res) setSearchResult(res);
        else setSearchResult([]);
      } else {
        setSearchResult([]);
      }
    };
    search();
  }, [searchString, resources]);
  const itemToString = (item: SearchResult | null) => {
    return item?.visningstekst ?? "";
  };
  const menuClassname =
    isOpen && searchResult.length
      ? "dropdown-menu d-block py-0"
      : "dropdown-menu py-0";
  return (
    <Downshift
      inputId={inputId}
      itemToString={itemToString}
      isOpen={isOpen}
      onOuterClick={() => setIsOpen(false)}
      onSelect={(i) => {
        if (i) {
          setSearchString(i.visningstekst);
          const feat = new GeoJSON().readFeature(i.geometri);
          zoomToFeature(map, feat);
          if (onResult) onResult(i);
        }
        setIsOpen(false);
        inputRef.current?.blur();
      }}
    >
      {({
        isOpen,
        highlightedIndex,
        selectItem,
        getItemProps,
        getMenuProps,
        getInputProps,
      }) => (
        <div
          className={
            containerClassName ? containerClassName : "c-search shadow"
          }
          style={containerStyle}
          aria-label="Søger: Søg adresse, stednavn, mm."
        >
          <input
            autoComplete="off"
            ref={inputRef}
            {...getInputProps({
              onChange: (e) => setSearchString(e.target.value),
              onKeyDown: (e) => {
                if (e.key === "Escape") {
                  setSearchString("");
                }
              },
              onFocus: () => setIsOpen(true),
            })}
            className={
              inputClassName
                ? inputClassName
                : "form-control border-0 px-3 py-2"
            }
            style={{ ...{ minWidth: "370px" }, ...inputStyle }}
            type="search"
            placeholder={
              placeholder ? placeholder : "Søg adresse, stednavn, mm."
            }
            value={searchString}
          ></input>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
          <ul
            {...getMenuProps()}
            className={menuClassname}
            style={{
              ...{
                maxHeight: 400,
                overflowY: "auto",
                margin: 0,
              },
              ...listStyle,
            }}
          >
            {isOpen &&
              searchResult.map((item, index) => {
                return (
                  <li
                    {...getItemProps({ key: index, index, item })}
                    title={item.visningstekst}
                    className="dropdown-item"
                    onClick={() => selectItem(item)}
                    style={{
                      ...{
                        cursor: "pointer",
                        width: 368,
                        overflowX: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        backgroundColor:
                          highlightedIndex === index ? "#e9ecef" : undefined,
                      },
                      ...listItemStyle,
                    }}
                  >
                    {item.visningstekst}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </Downshift>
  );
}
